footer{
	position: absolute;
	bottom: 0;
	width: 100%;


	.footer-inner{
		border-top: 1px solid $grey;
		padding: 25px 0;

		.newsletter-col{

			.newsletter-cta{

				.newsletter-txt{
					color: $grey;
					display: inline-block;
					width: 200px;
					margin-right: 20px;
					font-size: 14px;
					line-height: 20px;
				}

				.newsletter-btn{
					display: inline-block;
					text-transform: uppercase;
					border: 1px solid $grey;
					padding: 8px 15px;
					vertical-align: top;
					text-decoration: none;
					position: relative;

					span{
						color: $grey;
						font-weight: 500;
						display: inline-block;
						z-index: 2;
						position: relative;
						transition: $trans1;
					}

					&::before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 0;
						background-color: $grey;
						transition: $trans3;
						z-index: 1;
					}

					&:hover{
						span{
							color: $black;
						}
						&::before{
							width: 100%;
						}
					}
				}

			}
		}

		.contact-col{
			text-align: right;
			.contact-cta{
				color: $grey;
				font-size: 14px;
				display: inline-block;
				padding-right: 50px;
				position: relative;
				top: 7px;
				
				@include media-breakpoint-down(lg){
					text-align: left;
					float: left;
				}

				a{
					text-decoration: none;
					color: $white;
					opacity: 0.6;
					transition: $trans1;

					&:hover{
						opacity: 1;
					}
					
					svg{
						width: 20px;
						margin-left: 5px;
						margin-right: 3px;
					}

					p{
						display: inline-block;
						transition: $trans1;
						margin-bottom: 0;
					}
				}
			}

			.credit-cta{
				display: inline-block;
				text-align: center;
				font-size: 14px;
				line-height: 20px;
				padding-left: 20px;
				border-left: 1px solid $grey;
				transition: $trans1;
				float: right;

				@include media-breakpoint-down(sm){
					margin-top: 20px;
					border-left: 0;
				}

				a{
					color: $grey;
					text-decoration: none;
					transition: $trans1;
				}

				&:hover{
					border-left: 1px solid $white;
					
					a{
						color: $white;
					}
				}
				
			}
		}
	}

	.footer-overlay {
		overflow: hidden;
		width: 100vw;
		height: 150px;
		margin: 0 calc(50% - 50vw);
		z-index: -10;
		position: absolute;
		background: linear-gradient(0deg,#000 0,transparent);
		opacity: .5;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
		bottom: 0;
	}

}

