.single-nummer{
	.main-filter{
		opacity: 0.6 ;
	}

	.menu-item-155{
		.nav-link{
			&::after{
				width: 100% !important;
			}
		}
	}

	.title-wrap{
		margin-bottom: 30px;

		h1{
			font-weight: 500;
			font-size: 48px;
			margin: 0;
			margin-bottom: 8px;
			text-align: left;
			text-transform: none;
		}

		.auteur{
			display: block;
			font-weight: 400;
			font-style: italic;
			font-size: 16px;
			color: $grey-dark;
			line-height: 1.16667;
		}
	}

	.article-body{
		color: $white;
		font-size: 18px;

		p{
			font-size: 18px;
		}
	}

	.album-col{
		.album{
			@include media-breakpoint-up(md){
				max-width: 285px;
			}

			.info-wrap{
				margin-top: 25px;
			}
		}
	}


}