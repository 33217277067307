@font-face {
 font-family:prenton;
 src:url(https://use.typekit.net/af/2e2cf0/00000000000000003b9afb7d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"),
 url(https://use.typekit.net/af/2e2cf0/00000000000000003b9afb7d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"),
 url(https://use.typekit.net/af/2e2cf0/00000000000000003b9afb7d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");
 font-weight:400;
 font-style:normal;
 font-stretch:normal;
 font-display:auto;
}
@font-face {
 font-family:prenton;
 src:url(https://use.typekit.net/af/09383e/00000000000000003b9afb7f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("woff2"),
 url(https://use.typekit.net/af/09383e/00000000000000003b9afb7f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("woff"),
 url(https://use.typekit.net/af/09383e/00000000000000003b9afb7f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("opentype");
 font-weight:400;
 font-style:italic;
 font-stretch:normal;
 font-display:auto;
}
@font-face {
 font-family:prenton;
 src:url(https://use.typekit.net/af/a3fdb1/00000000000000003b9afb7c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff2"),
 url(https://use.typekit.net/af/a3fdb1/00000000000000003b9afb7c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff"),
 url(https://use.typekit.net/af/a3fdb1/00000000000000003b9afb7c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("opentype");
 font-weight:300;
 font-style:normal;
 font-stretch:normal;
 font-display:auto;
}
@font-face {
 font-family:prenton;
 src:url(https://use.typekit.net/af/a0962d/00000000000000003b9afb80/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff2"),
 url(https://use.typekit.net/af/a0962d/00000000000000003b9afb80/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff"),
 url(https://use.typekit.net/af/a0962d/00000000000000003b9afb80/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("opentype");
 font-weight:500;
 font-style:normal;
 font-stretch:normal;
 font-display:auto;
}
@font-face {
 font-family:prenton;
 src:url(https://use.typekit.net/af/44f725/00000000000000003b9afb83/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),
 url(https://use.typekit.net/af/44f725/00000000000000003b9afb83/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),
 url(https://use.typekit.net/af/44f725/00000000000000003b9afb83/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");
 font-weight:700;
 font-style:normal;
 font-stretch:normal;
 font-display:auto;
}