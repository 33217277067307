/**
 * Forms
 **/

.subscribe-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;    
    margin-bottom: 50px;
}

.subscribe{
    label {
        color: $white;
        font-weight: 500;
        font-size: 12px;
    }

    input, select {
        width: 500px;
        height: 40px;
        background-color: transparent;
        border: 1px solid white;
        color: white;
        margin: 2px 0 20px;
        padding-left: 10px;
        font-weight: 500;
        font-size: 14px;

        &:focus{
            box-shadow: none;
            outline: 0;
        }
    }

    button{
        width: 500px;
        height: 40px;
        text-transform: uppercase;
        font-family: Prenton;
        font-weight: 500;
        color: #000;
        font-size: 14px;
        letter-spacing: 2px;
        background-color: #fff;
        border: none;
        -webkit-transition: background .3s ease;
        transition: background .3s ease;
    }        
}