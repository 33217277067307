.album-col{
	padding-left: 10px;
	padding-right: 10px;

	.album{
		margin-bottom: 50px;

		.cover-wrap{
			img{
				width: 100%;
			}
		}

		.info-wrap{
			margin-top: 10px;

			.year{
				color: $grey-dark;
			}

			.logo{
				max-width: 200px;
				margin-bottom: 3px;
			}

			h2{
				color: $white;
				text-transform: uppercase;
				font-size: 18px;
				font-weight: 400;
			}

			.album-links{
				a{
					display: inline-block;
					margin-right: 10px;
					position: relative;
					opacity: 0.7;
					transition: $trans3;

					&:hover{
						opacity: 1;
					}

					&:last-of-type{
						margin-right: 0;
					}

					img{
						width: 70px;
					}
				}

				.spotify{
					top: 2px;
				}
			}

			.tracks{
				padding-left: 0;
				list-style: none;
				counter-reset: tracks;
				margin-top: 20px;

				li{
				 	counter-increment: tracks;
				 	margin-bottom: 10px;
				 	

				 	&::before{
				  		content: counter(tracks);
				  		color: $grey-dark;
				  		width: 30px;
				  		display: inline-block;
				  		font-size: 14px;
				  		vertical-align: top;
				  		position: relative;
				  		top: 2px;
				 	}

					a{
						text-decoration: none;
						transition: $trans3;
						color: $white;
						font-size: 18px;
						display: inline-block;
						position: relative;
						max-width: calc(100% - 30px);

						&::after{
							content: '';
							height: 2px;
							width: 0;
							background-color: $grey-darker;
							transition: $trans3;
							position: absolute;
							bottom: 1px;
							left: 0;
						}

						&:hover{
							&::after{
								width: 100%;
							}
						}
					}
				}

				.active-link{
					font-size: 18px;
					color: $grey-dark;
				}
			}
		}
	}
}