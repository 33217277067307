header{
	padding-top: 40px;
	padding-bottom: 50px;
	
	@include media-breakpoint-up(md){
		padding-top: 75px;		
	}

	.nav-col{

		.nav-wrapper{

			.navbar-brand{
				max-width: 300px;
				display: inline-block;
			}

			#nav-main{
				#menu-entries{
					.navbar-nav{
						.nav-item{
							padding-right: 20px;

							&:last-of-type{
								padding-right: 0;
							}

							.nav-link{
								color: $white;
								padding: 0;
								text-transform: uppercase;
								font-size: 14px;
								position: relative;
								transition: $trans1;

								&::after{
									content: '';
									position: absolute;
									bottom: -3px;
									left: 0;
									height: 2px;
									width: 0;
									transition: $trans3;
									background-color: $white;
								}

								&:hover{
									&::after{
										width: 100%;
									}
								}
							}

						}

						.current_page_item{
							.nav-link{
								&::after{
									width: 100% !important;
								}
							}
						}


						@include media-breakpoint-down(lg) {
							display: block !important;
							margin-bottom: 15px;

							.nav-item{
								display: inline-block !important;

								.nav-link{
									display: inline-block;
								}
							}
						}

					}
				}
			}

		}
	}

	.socials-col{

		.socials-nav{
			padding: 0;
			float: right;

			@include media-breakpoint-down(lg) {
				float: none;
			}

			@include media-breakpoint-between(md,lg){
				margin-top: 8px;
				float: right;
			}

			li{
				display: inline-block;
				list-style: none;
				padding-left: 20px;
				transition: $trans3;
				position: relative;
				bottom: 0;

				&:hover{
					bottom: 3px;
				}

				&:first-of-type{
					padding-left: 0;
				}

				a{
					img{
						height: 20px;
					}
				}
			}
		}
	}
}


.navbar-toggler{
	display: none;
}
