/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$white: #FFFFFF;
$grey: rgba(255, 255, 255, 0.6);
$grey-dark: #999;
$grey-darker: #2c2c2c;
$black: #000000;

/**
 * Transitions
 **/

$trans1: all ease-in 0.1s;
$trans2: all ease-in 0.2s;
$trans3: all ease 0.5s;