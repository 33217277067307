.row:before, .row:after {
	display: none !important;
}

.container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
	margin: 0 auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 1440px !important;
	width: 95% !important;

	@include media-breakpoint-up(md){
		width: 80% !important;	
	}
}

body{
	min-height: 100vh;
	background-color: $black !important;
	position: relative;

	.main-background{
		background-size: cover !important;
		background-position: center center !important;
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
	}

	.main-filter{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: $black;
		opacity: 0.05;
	}

	.main-content-wrap{
		position: relative;
		z-index: 2;
		min-height: 100vh;
		padding-bottom: 125px;
	}
}

.content-wrapper{
	@include media-breakpoint-down(sm){
		padding-bottom: 50px;		
	}
}