*{
	font-family:prenton;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p, ul, ol, li, a{
	color: $white;
	font-size: 14px;
}

h1{
	text-align: left;
	text-transform: uppercase;
	font-weight: 400;
	color: $white;
	font-size: 48px;
	margin: 50px 0 60px 0;
	letter-spacing: 3px;
}